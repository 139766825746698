.scroll {
    height: 100%;
    overflow-y: scroll;
}

.dcl.field {
    min-width: 0;
}

html, body, document, #root {
    height: 100%;
}

.dcl.page {
    margin-top: 20px;
}
.dcl.page .full.height {
}
.dcl.page .eight.wide.column {
    margin-top: 10px;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

@media only screen and (min-width: 1200px) {

  .ui.container {
    width: 100%;
  }
}